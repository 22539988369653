
import { defineComponent } from 'vue';

const BaseInformation = defineComponent({
  emits: ['close'],

  props: {
    title: String,
    variant: {
      type: String,
      required: true,
      validator: (value: string): boolean => (
        ['success', 'danger'].indexOf(value) !== -1
      ),
    },
  },
});

export default BaseInformation;
