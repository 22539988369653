
import { computed, defineComponent, WritableComputedRef } from 'vue';
import { Form } from 'vee-validate';
import BasePasswordInput from '@/components/base/BasePasswordInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';

const SignUpForm = defineComponent({
  components: { BasePasswordInput, BaseButton, Form },

  emits: ['update:newPassword', 'update:confirmPassword', 'submit'],

  props: {
    newPassword: {
      type: String,
      required: true,
    },
    confirmPassword: {
      type: String,
      required: true,
    },
    loading: Boolean,
  },

  setup(props, { emit }) {
    const validationSchema = {
      newPassword: 'required|min:8',
      confirmPassword: 'required|min:8|confirmed:@newPassword',
    };

    const localNewPassword: WritableComputedRef<string> = computed({
      get: (): string => props.newPassword,
      set: (value: string): void => emit('update:newPassword', value),
    });

    const localConfirmPassword: WritableComputedRef<string> = computed({
      get: (): string => props.confirmPassword,
      set: (value: string): void => emit('update:confirmPassword', value),
    });

    return { localNewPassword, localConfirmPassword, validationSchema };
  },
});

export default SignUpForm;
