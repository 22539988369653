
import { defineComponent, ref, computed } from 'vue';
import BaseCard from '@/components/base/BaseCard';
import ResetPasswordForm from '@/components/forms/ResetPasswordForm.vue';
import BaseAlertMessage from '@/components/base/BaseAlertMessage.vue';
import BaseInformation from '@/components/base/BaseInformation.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import RequestStatus from '@/constants/RequestStatus';
import useAuth from '@/mixins/useAuth';
import { useRoute } from 'vue-router';
import * as Sentry from '@sentry/vue';

const TheResetPasswod = defineComponent({
  components: {
    BaseCard,
    ResetPasswordForm,
    BaseAlertMessage,
    BaseButton,
    BaseInformation,
  },

  setup() {
    const route = useRoute();
    const { recoverUserPassword } = useAuth();
    const newPassword = ref<string>('');
    const confirmPassword = ref<string>('');
    const status = ref<RequestStatus>(RequestStatus.Initial);

    const isLoading = computed(() => status.value === RequestStatus.Loading);
    const isError = computed(() => status.value === RequestStatus.Error);
    const isSuccess = computed(() => status.value === RequestStatus.Success);

    const onSubmit = async (): Promise<void> => {
      status.value = RequestStatus.Loading;

      const { token } = route.query;

      console.log(token);

      const response = await recoverUserPassword(newPassword.value, token as string);

      if (!response) {
        status.value = RequestStatus.Error;
        Sentry.captureException(new Error('Could not reset password'));

        return;
      }

      status.value = RequestStatus.Success;
    };

    return {
      isLoading,
      isError,
      isSuccess,
      newPassword,
      confirmPassword,
      onSubmit,
    };
  },
});

export default TheResetPasswod;
