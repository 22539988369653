import { createVNode as _createVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "max-w-3xl text-center" }
const _hoisted_2 = { class: "text-30 mb-7 font-semibold" }
const _hoisted_3 = { class: "text-18 text-sub-title" }
const _hoisted_4 = { class: "mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("img", {
      class: "mb-5 w-24 mx-auto",
      src: require(`@/assets/svg/info-${_ctx.variant}.svg`),
      alt: _ctx.variant
    }, null, 8, ["src", "alt"]),
    _createVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "text")
    ]),
    _createVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "actions")
    ])
  ]))
}