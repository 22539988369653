import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasePasswordInput = _resolveComponent("BasePasswordInput")
  const _component_BaseButton = _resolveComponent("BaseButton")
  const _component_Form = _resolveComponent("Form")

  return (_openBlock(), _createBlock(_component_Form, {
    onSubmit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('submit'))),
    "validation-schema": _ctx.validationSchema
  }, {
    default: _withCtx(() => [
      _createVNode("p", null, _toDisplayString(_ctx.$t('forms.reset-password.info')), 1),
      _createVNode(_component_BasePasswordInput, {
        modelValue: _ctx.localNewPassword,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.localNewPassword = $event)),
        class: "mt-4",
        name: "newPassword",
        label: _ctx.$t('forms.reset-password.new-password.label'),
        placeholder: _ctx.$t('forms.reset-password.new-password.placeholder'),
        "input-classes": "w-full h-11",
        togglable: ""
      }, null, 8, ["modelValue", "label", "placeholder"]),
      _createVNode(_component_BasePasswordInput, {
        modelValue: _ctx.localConfirmPassword,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.localConfirmPassword = $event)),
        name: "confirmPassword",
        class: "mt-4",
        label: _ctx.$t('forms.reset-password.confirm-password.label'),
        placeholder: _ctx.$t('forms.reset-password.confirm-password.placeholder'),
        "input-classes": "w-full h-11",
        togglable: ""
      }, null, 8, ["modelValue", "label", "placeholder"]),
      _createVNode(_component_BaseButton, {
        class: "mt-5",
        loading: _ctx.loading
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('actions.submit')), 1)
        ]),
        _: 1
      }, 8, ["loading"])
    ]),
    _: 1
  }, 8, ["validation-schema"]))
}